// consumer_journey_trainTicketcalculatePrice 根据出差单获取预下单火车票订单统计金额
// http://yapi.yinzhitravel.com/project/52/interface/api/14281

const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_trainTicketcalculatePrice = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/trainTicketcalculatePrice',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_trainTicketcalculatePrice;